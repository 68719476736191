import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import "../Applications/Applications.css";
import XFA_API, { Application, Role } from "../API/XFA_API";
import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Route, Routes, useLocation } from "react-router-dom";
import { trackEvent } from "../intercom/intercomProvider";
import AddIcon from "@mui/icons-material/Add";
import ConfirmationDialog from "../Dialogs/ConfirmationDialog";
import NavigationService from "../../utils/NavigationService";
import ApplicationCard from "../Applications/ApplicationCard";
import ConnectGoogle from "../Applications/NewOrEditPage/Google/ConnectGoogle";
import ConnectMicrosoft from "../Applications/NewOrEditPage/ConnectMicrosoft";
import NewOrEditApplication from "../Applications/NewOrEditPage/NewOrEditApplication";
import DiscoveryOnboarding from "../DiscoveryOnboarding/DiscoveryOnboarding";
import {
  startGoogleDiscovery,
  startMicrosoftDiscovery,
} from "../../utils/DiscoveryService";
import { InviteUsersDialog } from "../Users/InviteUsersDialog";

interface DiscoveryProps {
  role: Role;
}

const Discovery: React.FC<DiscoveryProps> = (props: DiscoveryProps) => {
  return (
    <Routes>
      <Route path="" element={<Overview {...props} />} />
      <Route
        path="connect-google"
        element={
          <ConnectGoogle
            role={props.role}
            organizationId={props.role.organization.organization_id}
          />
        }
      />
      <Route
        path="connect-microsoft"
        element={
          <ConnectMicrosoft
            role={props.role}
            organizationId={props.role.organization.organization_id}
          />
        }
      />
      <Route
        path=":applicationId"
        element={
          <NewOrEditApplication
            role={props.role}
            organizationId={props.role.organization.organization_id}
          />
        }
      />
    </Routes>
  );
};

interface OverviewProps {
  role: Role;
}

const Overview: React.FC<OverviewProps> = (props: OverviewProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [error, setError] = React.useState<string>("");
  const [applications, setApplications] = React.useState<
    Application[] | undefined
  >(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [deleteInProgress, setDeleteInProgress] =
    React.useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = React.useState<
    Application | undefined
  >(undefined);
  const [newDiscovery, setNewDiscovery] = React.useState<boolean>(false);
  const [selectedOption, setSelectedOption] = React.useState("");
  const [openInviteUsersDialog, setOpenInviteUsersDialog] =
    useState<boolean>(false);

  const openInviteUsers = useCallback(() => {
    setOpenInviteUsersDialog(true);
  }, []);

  const closeInviteUsers = useCallback((refresh: boolean) => {
    setOpenInviteUsersDialog(false);
  }, []);

  const refreshApplications = React.useCallback(async () => {
    setLoading(true);
    try {
      const applications = await XFA_API.getApplications(
        props.role.organization.organization_id,
      );
      setLoading(false);
      const filteredApplications = applications.filter(
        (app) => app.Type === "Microsoft" || app.Type === "Google",
      );
      setApplications(filteredApplications);
      if (filteredApplications.length === 0) {
        setSelectedOption("");
      }
    } catch (error: any) {
      setLoading(false);
      setError(t("applications.error"));
    }
  }, [t, props.role.organization.organization_id]);

  React.useEffect(() => {
    refreshApplications();
  }, [refreshApplications]);

  return (
    <div>
      <div className="flex">
        <Typography variant="pagetitle" color="primary">
          {t("navigation.discovery")}
        </Typography>
        <div style={{ marginLeft: "auto", display: "flex", gap: 4 }}>
          {newDiscovery && (
            <Button
              variant="secondary"
              onClick={() => {
                setNewDiscovery(false);
              }}
            >
              {t("Users.delete.cancel")}
            </Button>
          )}

          {applications && applications.length > 0 && !newDiscovery && (
            <>
              <Button variant="secondary" onClick={openInviteUsers}>
                {t("Users.invite")}
              </Button>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  trackEvent("Creating new application");
                  setNewDiscovery(true);
                }}
              >
                {t("applications.new")}
              </Button>
            </>
          )}
          {(newDiscovery ||
            !applications ||
            (applications.length === 0 && !newDiscovery)) && (
            <>
              <Button variant="secondary" onClick={openInviteUsers}>
                {t("Users.invite")}
              </Button>
              <Button
                variant="contained"
                disabled={selectedOption === ""}
                onClick={() => {
                  if (selectedOption === "google") {
                    startGoogleDiscovery(props.role);
                  } else if (selectedOption === "microsoft") {
                    startMicrosoftDiscovery(props.role);
                  }
                }}
              >
                {t("login.discovery.buttons.connectNow")}
              </Button>
            </>
          )}
        </div>
      </div>
      <Divider style={{ marginTop: 16 }} />
      <div className="w-full" data-cy="applications">
        {error && (
          <div style={{ marginBottom: 15 }}>
            <Alert severity="error">{error}</Alert>
          </div>
        )}
        {(applications && applications.length === 0) || newDiscovery ? (
          <DiscoveryOnboarding
            setSelectedOption={setSelectedOption}
            emptyState={true}
            onOtherIdentificationMethod={() => {
              NavigationService.navigateToNewApplication();
            }}
          />
        ) : loading ? (
          <div className="center">
            <CircularProgress />{" "}
          </div>
        ) : (
          <>
            <Grid
              container
              className="overview"
              spacing={1}
              style={{ marginTop: 8 }}
            >
              {applications &&
                applications
                  .sort((a, b) => {
                    if (
                      a.UniquePurpose !== undefined &&
                      b.UniquePurpose === undefined
                    ) {
                      return -1;
                    }
                    if (
                      a.UniquePurpose === undefined &&
                      b.UniquePurpose !== undefined
                    ) {
                      return 1;
                    }

                    return a.Name.localeCompare(b.Name);
                  })
                  .map((application: Application) => {
                    const description =
                      application.Type === "MicrosoftEAM"
                        ? "Microsoft EAM"
                        : application.Type === "Okta"
                          ? "Okta"
                          : application.Type === "Onelogin"
                            ? "Onelogin"
                            : application.OAuthConfiguration !== undefined
                              ? "OAuth"
                              : application.SAMLConfiguration !== undefined
                                ? "SAML"
                                : (application.Type ??
                                  t("applications.custom"));
                    return (
                      <Grid item key={application.ApplicationID}>
                        <ApplicationCard
                          enabled={application.Enabled}
                          default={application.UniquePurpose !== undefined}
                          title={application.Name}
                          logo={application.IconUrl}
                          description={description}
                          skipped={application.Policies?.skip ?? false}
                          filtered={
                            application.FilterDesktop ||
                            application.FilterMobile ||
                            (application.FilteredEmail?.length ?? 0) > 0
                          }
                          showStatus={false}
                          setEnabled={undefined}
                          onManage={() => {
                            NavigationService.navigateToDiscovery(
                              application.ApplicationID,
                            );
                          }}
                          onDelete={() => {
                            setConfirmDelete(application);
                          }}
                        />
                      </Grid>
                    );
                  })}
            </Grid>
          </>
        )}
      </div>
      {openInviteUsersDialog && (
        <InviteUsersDialog
          role={props.role}
          users={[]}
          onInvite={XFA_API.inviteUser}
          onClose={closeInviteUsers}
        />
      )}
      {/* Confirm delete dialog */}
      {confirmDelete !== undefined && (
        <ConfirmationDialog
          title={t("applications.delete.confirmTitle") + confirmDelete?.Name}
          cancelText={t("applications.delete.cancel")}
          confirmText={t("applications.delete.confirm")}
          inProgress={deleteInProgress}
          onCancel={() => {
            setConfirmDelete(undefined);
          }}
          onConfirm={() => {
            setDeleteInProgress(true);
            XFA_API.deleteApplication(confirmDelete!).then(() => {
              setConfirmDelete(undefined);
              refreshApplications().then((_) => setDeleteInProgress(false));
            });
          }}
        />
      )}
    </div>
  );
};
export default Discovery;
