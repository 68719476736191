import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { BillingSettings } from "../../API/XFA_API";
import "./TrialBar.css";

interface TrialBarProps {
  settings: BillingSettings;
}

const TrialBar: React.FC<TrialBarProps> = ({ settings }) => {
  const { t } = useTranslation();

  return (
    <div className="trial-bar">
      <Typography variant="body2">
        {t("Billing.trialEnds")}
        {settings.TrialEndDate.substring(6)}{" "}
        {t("months." + settings.TrialEndDate.substring(4, 6))}{" "}
        {settings.TrialEndDate.substring(0, 4)}
      </Typography>
    </div>
  );
};

export default TrialBar;
