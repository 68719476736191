import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Alert, CircularProgress } from "@mui/material";
import XFA_API, { Role } from "../API/XFA_API";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import VantaIcon from "./vanta.svg";
import ThoropassIcon from "./thoropass.png";
import Button from "@mui/material/Button";
import { getCsrfToken } from "./Csrf";
import { getCodeFromVanta, getVantaRedirectUri } from "./Vanta";
import { getCodeFromThoropass, getThoropassRedirectUri } from "./Thoropass";

interface ConnectorProps {
  role: Role;
}

const Connector: React.FC<ConnectorProps> = (props: ConnectorProps) => {
  const { t } = useTranslation();
  const { connector } = useParams<{ connector: string }>();

  const [loading, setLoading] = React.useState(false);
  const [connected, setConnected] = React.useState(true);
  const [error, setError] = React.useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const exchangeVantaCodeOnAPI = async (
    code: string,
    organizationId: string,
    redirectUri: string,
  ) => {
    /*
      - RedirectURL: /connect/vanta (must be configured with Vanta)
      - state must be the same as the one passed in the InstallationURL (cookie check)
      - forward querystring to xfa-api (code, source_id/organizationId, redirect_uri)
    */

    setLoading(true);
    try {
      await XFA_API.connectVanta(code, organizationId, redirectUri);
      setLoading(false);
      setConnected(true);
    } catch (error: any) {
      setLoading(false);
      setError(t("vanta.error"));
    }
  };

  const exchangeThoropassCodeOnAPI = React.useCallback(
    (
      code: string,
      code_verifier: string,
      organizationId: string,
      redirectUri: string,
    ) => {
      setLoading(true);
      XFA_API.connectThoropass(code, code_verifier, organizationId, redirectUri)
        .then((response) => {
          setLoading(false);
          setConnected(true);
        })
        .catch((error) => {
          setLoading(false);
          setError(t("thoropass.error"));
        });
    },
    [],
  );

  React.useEffect(() => {
    // check if we have a code
    switch (connector) {
      case "vanta":
        if (searchParams.has("code") && searchParams.has("state")) {
          const code = searchParams.get("code");
          const state = searchParams.get("state");
          setSearchParams({});

          // check if csrf token is valid
          if (state !== getCsrfToken()) {
            setError(t("vanta.csrfError"));
          }

          // redirect_uri
          const redirectUri = getVantaRedirectUri();

          // exchange code for token
          exchangeVantaCodeOnAPI(
            code as string,
            props.role.organization.organization_id,
            redirectUri,
          );
        }
        break;
      case "thoropass":
        if (searchParams.has("code")) {
          const code = searchParams.get("code");
          setSearchParams({});

          const code_verifier = getCsrfToken();

          if (!code_verifier) {
            setError(t("thoropass.csrfError"));
          }

          // redirect_uri
          const redirectUri = getThoropassRedirectUri(
            props.role.organization.organization_id,
          );

          // exchange code for token
          exchangeThoropassCodeOnAPI(
            code as string,
            code_verifier as string,
            props.role.organization.organization_id,
            redirectUri,
          );
        }
        break;
    }
    //eslint-disable-next-line
  }, []);

  const navigate = useNavigate();

  if (connector === "vanta") {
    return (
      <div className="flex h-full justify-center items-center mt-5">
        <div className="flex flex-col justify-center items-center">
          <img src={VantaIcon} alt="Vanta" className="h-52" />
          {loading ? (
            <CircularProgress className="m-2" />
          ) : error ? (
            <Alert severity="error" className="m-2">
              {error}
            </Alert>
          ) : connected ? (
            <>
              <Typography variant="h5" className="p-3">
                {t("connectors.connectedToVanta")}
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  navigate("/devices");
                }}
              >
                {t("connectors.backToDevices")}
              </Button>
            </>
          ) : (
            <>
              <Typography variant="h5" className="p-3">
                {t("connectors.connectToVanta")}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  return getCodeFromVanta(
                    props.role.organization.organization_id,
                  );
                }}
              >
                {t("connectors.connectOrganization")}{" "}
                {props.role.organization.name}
              </Button>
            </>
          )}
        </div>
      </div>
    );
  } else if (connector === "thoropass") {
    return (
      <div className="flex h-full justify-center items-center mt-5">
        <div className="flex flex-col justify-center items-center">
          <div className="max-w-52">
            <img src={ThoropassIcon} alt="Thoropass" />
          </div>
          {loading ? (
            <CircularProgress className="m-2" />
          ) : error ? (
            <Alert severity="error" className="m-2">
              {error}
            </Alert>
          ) : connected ? (
            <>
              <Typography variant="h5" className="p-3">
                {t("connectors.connectedToThoropass")}
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  navigate("/devices");
                }}
              >
                {t("connectors.backToDevices")}
              </Button>
            </>
          ) : (
            <>
              <Typography variant="h5" className="p-3">
                {t("connectors.connectToThoropass")}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  return getCodeFromThoropass(
                    props.role.organization.organization_id,
                  );
                }}
              >
                {t("connectors.connectOrganization")}{" "}
                {props.role.organization.name}
              </Button>
            </>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="mt-5">
        <Alert severity="error">
          <Typography variant="h5">{t("error")}</Typography>
          <Typography variant="body1">{t("errorConnector")}</Typography>
        </Alert>
      </div>
    );
  }
};

export default Connector;
