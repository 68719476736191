import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import "../Applications/Applications.css";
import XFA_API, { FullOrganization, Role } from "../API/XFA_API";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Route, Routes, useLocation } from "react-router-dom";
import VerificationMailPreview from "../../images/verification-mail-preview.png";

import BooleanSetting from "../Applications/NewOrEditPage/BooleanSetting";
import CloseIcon from "@mui/icons-material/Close";

interface AwarenessProps {
  role: Role;
}

const Awareness: React.FC<AwarenessProps> = (props: AwarenessProps) => {
  return (
    <Routes>
      <Route path="" element={<Overview {...props} />} />
    </Routes>
  );
};

interface OverviewProps {
  role: Role;
}

const Overview: React.FC<OverviewProps> = (props: OverviewProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [error, setError] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(true);
  const [organization, setOrganization] = React.useState<FullOrganization>();

  const [autoVerifyMail, setAutoVerifyMail] = useState<boolean | undefined>(
    undefined,
  );
  const [hasDevices, setHasDevices] = useState<boolean>(false);
  const [sendingMail, setSendingMail] = useState<boolean>(false);
  const [mailSent, setMailSent] = useState<boolean>(false);
  const [learnMoreDialogOpen, setLearnMoreDialogOpen] =
    useState<boolean>(false);

  const refreshOrganization = React.useCallback(async () => {
    setLoading(true);
    try {
      const org = await XFA_API.getOrganizationFull(
        props.role.organization.organization_id,
      );
      if (!org) {
        setError(t("organization.error"));
        return;
      }
      setOrganization(org);
      setAutoVerifyMail(org.notify_discovered_devices);
    } catch (error: any) {
      setError(t("organization.error"));
    }
    setLoading(false);
  }, [t, props.role.organization.organization_id]);

  const refreshDevices = React.useCallback(async () => {
    setLoading(true);
    try {
      const devices = await XFA_API.getDevicesForUser(
        props.role.organization.organization_id,
        props.role.email,
      );
      setHasDevices((devices && devices.length > 0) ?? false);
    } catch (error: any) {
      setError(t("devices.error"));
    }
    setLoading(false);
  }, []);

  const updateOrganization = useCallback(
    async (autoVerifyMailChange: boolean) => {
      const updatedOrganization = {
        ...props.role.organization,
        notify_discovered_devices: autoVerifyMailChange,
      };

      await XFA_API.updateOrganization(
        props.role.organization.organization_id,
        updatedOrganization,
      );

      await refreshOrganization();
    },
    [autoVerifyMail, refreshOrganization, props.role.organization],
  );

  const handleAutoVerifyMailChange = (autoVerifyMailChange: boolean) => {
    setAutoVerifyMail(autoVerifyMailChange);
    updateOrganization(autoVerifyMailChange);
  };

  const SendTestEmail = async () => {
    localStorage.setItem("verifyMail", JSON.stringify(true));
    setSendingMail(true);
    XFA_API.sendVerificationRequestForUser(
      props.role.organization.organization_id,
      props.role.email,
    )
      .then(() => {
        setMailSent(true);
        setTimeout(() => {
          //remove confirmation message after 3 seconds
          setMailSent(false);
        }, 3000);
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => {
        setSendingMail(false);
      });
  };

  const handleLearnMoreClick = () => {
    localStorage.setItem("verifyMail", JSON.stringify(true));
    setLearnMoreDialogOpen(true);
  };

  const handleLearnMoreDialogClose = () => {
    setLearnMoreDialogOpen(false);
  };

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const showLearnMore = searchParams.get("showLearnMore");
    if (showLearnMore === "true") {
      setLearnMoreDialogOpen(true);
      localStorage.setItem("verifyMail", JSON.stringify(true));
    }
    refreshOrganization();
    refreshDevices();
  }, [location.search, refreshOrganization]);

  return (
    <div>
      <div className="flex">
        <Typography variant="pagetitle" color="primary">
          {t("navigation.awareness")}
        </Typography>
      </div>
      <Divider style={{ marginTop: 16 }} />
      <div className="w-full" data-cy="applications">
        {error && (
          <div style={{ marginBottom: 8, marginTop: 16, width: "50%" }}>
            <Alert
              severity="error"
              onClose={() => {
                setError("");
              }}
            >
              {error}
            </Alert>
          </div>
        )}
        {loading ? (
          <div className="center">
            <CircularProgress />{" "}
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
              }}
            >
              <div className="section" style={{ marginTop: 16, width: "50%" }}>
                <BooleanSetting
                  label={t("verify.autoVerifyMail")}
                  explanation={t("verify.autoVerifyMailDescription")}
                  value={autoVerifyMail ?? false}
                  onChange={handleAutoVerifyMailChange}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 16,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleLearnMoreClick();
                    }}
                  >
                    {t("verify.learnMore")}
                  </Button>
                  <Tooltip
                    title={!hasDevices ? t("verify.testNoDevices") : ""}
                    placement="right"
                  >
                    <div style={{ display: "flex" }}>
                      {mailSent && (
                        <div style={{ margin: "auto" }}>
                          {t("devices.verificationEmailSent")}
                        </div>
                      )}
                      {sendingMail && (
                        <div style={{ display: "flex" }}>
                          <CircularProgress
                            style={{
                              margin: "auto",
                              height: 20,
                              width: 20,
                            }}
                          />
                        </div>
                      )}
                      <Button
                        variant="contained"
                        disabled={!hasDevices}
                        onClick={() => {
                          SendTestEmail();
                        }}
                        style={{ marginLeft: 8, flex: 1 }}
                      >
                        {t("verify.testYourself")}
                      </Button>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
          </>
        )}
        <Dialog
          open={learnMoreDialogOpen}
          onClose={handleLearnMoreDialogClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            {t("verify.learnMoreDialogTitle")}
            <IconButton
              aria-label="close"
              onClick={handleLearnMoreDialogClose}
              style={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              {t("verify.learnMoreDialogDescription1")}
            </Typography>
            <Typography variant="body1" style={{ marginTop: 8 }}>
              {t("verify.learnMoreDialogDescription2")}
            </Typography>
            <Typography variant="body1" style={{ marginTop: 8 }}>
              {t("verify.learnMoreDialogDescription3")}
            </Typography>
            <img
              src={VerificationMailPreview}
              alt="Learn More"
              style={{ width: "100%", marginTop: 16, borderRadius: 16 }}
            />
          </DialogContent>
          <DialogActions>
            <Tooltip
              title={!hasDevices ? t("verify.testNoDevices") : ""}
              placement="right"
            >
              <span>
                <Button
                  variant="contained"
                  disabled={!hasDevices}
                  onClick={() => {
                    handleLearnMoreDialogClose();
                  }}
                >
                  {t("verify.testYourself")}
                </Button>
              </span>
            </Tooltip>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};
export default Awareness;
