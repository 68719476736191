import React from "react";
import "./GetStarted.css";
import { useTranslation } from "react-i18next";
import GetStartedTask from "./GetStartedTask";
import { Button } from "@mui/material";

export interface Task {
  id: number;
  text: string;
  completed: boolean;
  onClick: () => void;
  tooltip?: string;
  disabled?: boolean;
}

interface GetStartedProps {
  tasks: Task[];
  dismissable: boolean;
  onDismiss: () => void;
}

const GetStarted: React.FC<GetStartedProps> = ({
  tasks,
  dismissable,
  onDismiss,
}) => {
  const { t } = useTranslation();

  const completedTasks = tasks.filter((task) => task.completed).length;

  return (
    <div
      className={`get-started-container ${dismissable ? "dismissable" : ""}`}
    >
      <div className="get-started-header-container">
        <div>
          <div className="get-started-header">
            {t("overview.emptyState.getStartedTitle")}
          </div>
          <div className="get-started-counter">
            {t("overview.emptyState.completedCounter", {
              count: completedTasks,
              maxCount: tasks.length,
            })}
          </div>
        </div>
        <div className="get-started-dismissable">
          {dismissable && (
            <Button
              className="get-started-dismiss-button"
              variant="contained"
              onClick={onDismiss}
              style={{ marginLeft: "auto" }}
            >
              {t("overview.emptyState.dismissButton")}
            </Button>
          )}
        </div>
      </div>
      <div className="get-started-tasks">
        {tasks.map((task) => (
          <GetStartedTask
            key={task.id}
            text={task.text}
            completed={task.completed}
            onClick={task.onClick}
            disabled={task.disabled}
            tooltip={task.tooltip}
          />
        ))}
      </div>
    </div>
  );
};

export default GetStarted;
